import '@css/web.scss'

import {Carousel, Popover} from 'bootstrap';
import {initStoreCampaignIdInCookie} from "./lib/cid_links";
import {initSubheader} from "./lib/subheader";
import {initCookieConsent} from "./lib/cookie_consent";
import {initFreeform} from "./lib/freeform";


export class VbpPopover extends Popover {

}

document.addEventListener('DOMContentLoaded', () => {
    initCookieConsent();
    initStoreCampaignIdInCookie();
    initFreeform();

    document.addEventListener('scroll', () => {
        initSubheader();
    });
});

// Initialize all Bootstrap components
document.addEventListener('DOMContentLoaded', () => {
    let carousels = document.querySelectorAll('.carousel') as NodeListOf<HTMLElement>;
    carousels.forEach((carousel) => {
        new Carousel(carousel);
    })

    let popOvers = document.querySelectorAll('.custom-popover') as NodeListOf<HTMLElement>;
    popOvers.forEach((popover) => {
        new Popover(popover);
    })
});


document.addEventListener("DOMContentLoaded", function() {
    // Configuration options
    const FLYOUT_CONFIG = {
        triggerEvent: 'click', // Options: 'hover' or 'click'
        closeDelay: 200, // Delay in ms before closing the menu on mouseleave
        viewportPadding: 20 // Padding from viewport edges in px
    };
    
    console.log('Flyout menu initialization with config:', FLYOUT_CONFIG);
    
    const flyoutMenus = document.querySelectorAll(".flyout-trigger") as NodeListOf<HTMLElement>;
    console.log('Found flyout menus:', flyoutMenus.length);
    
    const timeouts = new Map<HTMLElement, number>();
    // Track which menu is currently open
    let currentOpenMenu: HTMLElement | null = null;

    function adjustMenuPosition(menuTrigger: HTMLElement, menu: HTMLElement) {
        const triggerRect = menuTrigger.getBoundingClientRect();
        const viewportWidth = document.documentElement.clientWidth;
        const padding = FLYOUT_CONFIG.viewportPadding;
        const menuWidth = menu.offsetWidth;

        // Check if menu is too wide to fit with padding
        if (menuWidth > viewportWidth - (padding * 2)) {
            // Menu is too wide - make it full width
            const leftPosition = -triggerRect.left;
            menu.style.width = '100vw';
            menu.style.left = `${leftPosition}px`;
            
            console.log('Menu too wide - using full width:', {
                menuWidth,
                viewportWidth,
                availableWidth: viewportWidth - (padding * 2)
            });
        } else {
            // Normal positioning with padding
            const minLeft = padding;
            const maxLeft = viewportWidth - padding - menuWidth;
            const idealLeft = triggerRect.left + (triggerRect.width / 2) - (menuWidth / 2);
            const clampedLeft = Math.max(minLeft, Math.min(maxLeft, idealLeft));
            
            const leftPosition = clampedLeft - triggerRect.left;

            menu.style.removeProperty('width');  // Remove full width if it was set
            menu.style.left = `${leftPosition}px`;

            console.log('Normal positioning:', {
                minLeft,
                maxLeft,
                idealLeft,
                clampedLeft,
                leftPadding: clampedLeft,
                rightPadding: viewportWidth - (clampedLeft + menuWidth)
            });
        }

        menu.style.transform = 'scaleY(1)';
    }

    // Helper function to check element visibility
    function checkVisibility(element: HTMLElement, label: string) {
        const computedStyle = window.getComputedStyle(element);
        // console.log(`${label} visibility check:`, {
        //     display: computedStyle.display,
        //     visibility: computedStyle.visibility,
        //     opacity: computedStyle.opacity,
        //     height: computedStyle.height,
        //     width: computedStyle.width,
        //     position: computedStyle.position,
        //     zIndex: computedStyle.zIndex,
        //     overflow: computedStyle.overflow
        // });
    }

    function showMenu(menuTrigger: HTMLElement) {
        console.log('Showing menu for', menuTrigger);
        // Clear this menu's timeout if exists
        const existingTimeout = timeouts.get(menuTrigger);
        if (existingTimeout) {
            clearTimeout(existingTimeout);
            timeouts.delete(menuTrigger);
        }

        // Hide all other menus
        flyoutMenus.forEach(other => {
            if (other !== menuTrigger) {
                const otherMenu = other.querySelector(".flyout-menu") as HTMLElement;
                if (otherMenu) {
                    other.classList.remove('show');
                }
            }
        });

        const menu = menuTrigger.querySelector(".flyout-menu") as HTMLElement;
        console.log('Menu element:', menu);
        
        // Set initial state for animation
        menu.style.transform = 'scaleY(0)';
        menu.style.opacity = '0';
        
        // Force a reflow to ensure the animation works
        void menu.offsetWidth;
        
        // Add the show class to trigger the animation
        menuTrigger.classList.add('show');
        menu.classList.add('show');
        
        // After a short delay, set the final transform state and adjust position
        setTimeout(() => {
            menu.style.opacity = '1';
            adjustMenuPosition(menuTrigger, menu);
        }, 10);
        
        // Check visibility after animation
        setTimeout(() => {
            checkVisibility(menu, 'Menu after animation');
        }, 250);
    }

    function hideMenu(menuTrigger: HTMLElement) {
        console.log('Hiding menu for', menuTrigger);
        const menu = menuTrigger.querySelector(".flyout-menu") as HTMLElement;
        
        // Set closing animation state
        menu.style.transform = 'scaleY(0)';
        menu.style.opacity = '0';
        
        // Remove the show classes
        menuTrigger.classList.remove('show');
        menu.classList.remove('show');
        
        // After the animation completes, hide the menu
        setTimeout(() => {
            if (!menuTrigger.classList.contains('show')) {
                menu.style.display = 'none';
            }
        }, 200); // Match the transition duration
        
        // For hover mode, we still need the timeout
        if (FLYOUT_CONFIG.triggerEvent === 'hover') {
            const timeoutId = window.setTimeout(() => {
                if (!menu.matches(':hover')) {
                    menuTrigger.classList.remove('show');
                }
            }, FLYOUT_CONFIG.closeDelay);
            timeouts.set(menuTrigger, timeoutId);
        }
    }

    if (flyoutMenus) {
        // Single document click handler for all menus
        document.addEventListener('click', function(e) {
            console.log('Document click event', e.target);
            if (currentOpenMenu && !currentOpenMenu.contains(e.target as Node)) {
                console.log('Closing menu due to outside click');
                hideMenu(currentOpenMenu);
                currentOpenMenu = null;
            }
        });
        
        flyoutMenus.forEach((menuTrigger) => {
            const menu = menuTrigger.querySelector(".flyout-menu") as HTMLElement;
            console.log('Processing menu trigger:', menuTrigger, 'Menu:', menu);
            
            // Ensure all menus are closed on page load
            if (menu) {
                menu.removeAttribute('style');
                menuTrigger.classList.remove('show');
                menu.style.transform = 'scaleY(0)';
            }
            
            // Set up event listeners based on configuration
            if (FLYOUT_CONFIG.triggerEvent === 'hover') {
                // Hover events
                menuTrigger.addEventListener("mouseenter", function() {
                    showMenu(this);
                });
                
                menuTrigger.addEventListener("mouseleave", function() {
                    hideMenu(this);
                });
                
                // Add hover event to the menu itself to maintain position
                if (menu) {
                    menu.addEventListener("mouseenter", function() {
                        adjustMenuPosition(menuTrigger, menu);
                    });
                }
            } else {
                // Click events - target the nav-link directly
                const navLink = menuTrigger.querySelector('.nav-link') as HTMLElement;
                if (navLink) {
                    // console.log('Setting up click event for nav link:', navLink);
                    navLink.addEventListener("click", function(this: HTMLElement, e) {
                        // console.log('Nav link clicked', this, e);
                        e.preventDefault();
                        e.stopPropagation();
                        
                        if (currentOpenMenu === menuTrigger) {
                            // console.log('This menu is already open, closing it');
                            hideMenu(menuTrigger);
                            currentOpenMenu = null;
                        } else {
                            // console.log('Opening this menu');
                            if (currentOpenMenu) {
                                hideMenu(currentOpenMenu);
                            }
                            showMenu(menuTrigger);
                            currentOpenMenu = menuTrigger;
                        }
                    });
                } else {
                    // console.error('No nav-link found in trigger:', menuTrigger);
                }
            }
        });

        // Adjust positions on window resize
        window.addEventListener('resize', () => {
            flyoutMenus.forEach(menuTrigger => {
                const menu = menuTrigger.querySelector(".flyout-menu") as HTMLElement;
                if (menu && menu.classList.contains('show')) {
                    adjustMenuPosition(menuTrigger, menu);
                }
            });
        });
    }
});